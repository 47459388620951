import "./Skills.sass"


import Scrollbars from "react-custom-scrollbars-2"
import { renderListofLi } from "../../../../Lib/utils"

import images_links from "../../../../Assets/Datas/images_links.json"
import {useContext} from "react";
import {ThemeContext} from "../../../../Lib/contexts/themeContext";
import {ButtonLink} from "../../../Others/ButtonLink/ButtonLink";
import {LangContext} from "../../../../Lib/contexts/LangContext";



export default function Skills() {
    const {theme, themeIndex } = useContext(ThemeContext);
    const {trad_content} = useContext(LangContext);

    return(
        <Scrollbars id="skills" autoHide autoHideTimeout={800} autoHideDuration={200}>
            <h1>{trad_content.skills.h1}</h1>
            <h2>{trad_content.skills.h2}</h2>
            <p style={{color: theme.colors[themeIndex].fontColor}}>Formation Développeur WEB, <strong>OpenClassrooms</strong> ({trad_content.skills.pFormation})</p>
            <h2>Tech Skills</h2>
            <h3>Langages</h3>
            <ul>
                <li style={{color: theme.colors[themeIndex].fontColor}}>HTML5 <i className="fa-brands fa-html5 fa-lg" style={{color: "#EF652A"}}></i></li>
                <li style={{color: theme.colors[themeIndex].fontColor}}>CSS3 <i className="fa-brands fa-css3-alt fa-lg" style={{color: "#2965F1"}}></i>, SASS <i className="fa-brands fa-sass fa-lg" style={{color: "#C95C95"}}></i></li>
                <li style={{color: theme.colors[themeIndex].fontColor}}>JS, JSX <i className="fa-brands fa-js fa-lg" style={{backgroundColor:"black", color: "#FDD61D"}}></i></li>
            </ul>
            <h3>Librairies : </h3>
            <ul>
                <li style={{color: theme.colors[themeIndex].fontColor}}>React <i className="fa-brands fa-react fa-lg" style={{color: "#225FAA"}}></i></li>
                <li style={{color: theme.colors[themeIndex].fontColor}}>jQuery <img src={images_links[0].jquery_Logo} alt="Logo de jQuery" /></li>
            </ul>
            <h3>{trad_content.skills.h3_1}</h3>
            <ul>
                {renderListofLi(trad_content.skills.others, theme.colors[themeIndex].fontColor)}
            </ul>
            <h3>{trad_content.skills.h3_2}</h3>
            <ul>
                <li style={{color: theme.colors[themeIndex].fontColor}}>GDScript <img src={images_links[0].Godot_Logo} alt="Logo de Godot" /></li>
                <li style={{color: theme.colors[themeIndex].fontColor}}>gsap <img src={images_links[0].gsap_Logo} alt="Logo de gsap" /></li>
            </ul>
            <h2>Soft Skills</h2>
            <ul>
                {renderListofLi(trad_content.skills.skills, theme.colors[themeIndex].fontColor)}
            </ul>

            <ButtonLink url="https://miro.com/app/board/uXjVMusDf5s=/?share_link_id=202977753396" text={trad_content.skills.skillsLink1} />
            <ButtonLink url="/Projects/Slider" text={trad_content.skills.skillsLink2} />
        </Scrollbars>
    )
}
