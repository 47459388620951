import "./ThemeOptionMobile.sass"
import {useContext, useState} from "react";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {Link} from "react-router-dom";

import images_links from "../../../Assets/Datas/images_links.json"
import {LangContext} from "../../../Lib/contexts/LangContext";


export default function ThemeOptionMobile() {
    const {theme, themeIndex, changeTheme, changeBackground} = useContext(ThemeContext)
    const [isVisible, setIsVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const [focusedIndex, setFocusedIndex] = useState(0);
    const {trad_menus} = useContext(LangContext);

    const handleClick = () => {
        setIsVisible(!isVisible);
        setIsFocused((prevIsFocused) => !prevIsFocused);
    };

    const renderThemeColors = (list) => {
        return list.map((color, index) => (
            <button key={"themeColor_choice_" + index}
                    className="themeColor_choice"
                    onClick={() => changeTheme(index)}
                    onFocus={() => handleFocus(index)}
                    style={{border: focusedIndex === index ? "2px solid white" : "2px solid rgba(35, 42, 50, 1)"}}
            >
                <div className="color color1" style={{backgroundColor: color.windowNotTransparent}}></div>
                <div className="color color2" style={{backgroundColor: color.header}}></div>
            </button>
        ))
    }

    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    const renderListOfBG_Mobile = (list) => {
        return list.map((background, index) => (
            <button className="backgroundPreviewButton"
                    onClick={() => {changeBackground(index)}}>
                <img src={background.url} alt="Background Preview" className="backgroundPreviewButton_bg"/>
                {
                    (background.logo_url !== "") ? (
                        <img src={background.logo_url} alt="" className="backgroundPreviewButton_logo" />
                    ) : (
                        <></>
                    )
                }
            </button>
        ))
    }

    return (
        (window.innerWidth < 1100) ? (
            <div id="themeOptionMobile">
                <div id="themeOptionText"
                     style={{
                         backgroundColor: theme.colors[themeIndex].dock,
                         border: "1px solid " + theme.colors[themeIndex].window
                     }}>
                    <p>Theme Options</p>
                    <button className={`button ${isVisible ? "open" : "closed"}`}
                            onClick={handleClick}
                    >
                        <i className="fa-solid fa-xl fa-chevron-up"></i>
                    </button>
                </div>
                <div id="themeOptionPanel" style={{
                    border: "1px solid " + theme.colors[themeIndex].window,
                    height: isFocused ? "600px" : "0",
                }}>
                    <div id="themeOptionPanel_content" style={{opacity: isFocused ? "1" : "0"}}>
                        <h2>{trad_menus.themeOptionMobile[0]}</h2>
                        <div id="backgroundsSelectorMobile">
                            {renderListOfBG_Mobile(theme.backgrounds)}
                        </div>
                        <h2>{trad_menus.themeOptionMobile[1]}</h2>
                        <div className="themeColorSelector">
                            {renderThemeColors(theme.colors)}
                        </div>
                        <Link to="https://www.myo-webdesign.fr/"
                              id="yannickIcon"
                              style={{backgroundColor: "transparent" }}>
                            <img src={images_links[2].yannickIcon} alt="Logo Yannick Myo"/>
                        </Link>
                    </div>
                </div>
            </div>
        ) : (
            <></>
        )

    )
}