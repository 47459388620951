import "./ThemeOptionWindow.sass"

import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {Link} from "react-router-dom";
import useVisible from "../../../Lib/hooks/useVisible";
import useHover from "../../../Lib/hooks/useHover";
import images_links from "../../../Assets/Datas/images_links.json"
import {LangContext} from "../../../Lib/contexts/LangContext";

export default function ThemeOptionWindow() {
    const {theme, themeIndex, changeTheme, backgroundIndex, changeBackground} = useContext(ThemeContext)
    const [tempThemeIndex, setTempThemeIndex] = useState(themeIndex)
    const [tempBackgroundIndex, setTempBackgroundIndex] = useState(backgroundIndex)
    const [hoveredItems, setHoveredItems] = useState({});
    const [focusedIndex, setFocusedIndex] = useState(0);
    const {visible, isVisible, isNotVisible} = useVisible();
    const {hover,isHovered, isNotHovered} = useHover();
    const {trad_menus} = useContext(LangContext);

    useEffect(() => {
        if(tempBackgroundIndex !== backgroundIndex || tempThemeIndex !== themeIndex) {
            isVisible()
        } else {
            isNotVisible()
        }
    }, [tempBackgroundIndex, tempThemeIndex]);





    const handleMouseEnter = (index) => {
        setHoveredItems((prevHoveredItems) => {
            return { ...prevHoveredItems, [index]: true };
        });
    };

    const handleMouseLeave = (index) => {
        setHoveredItems((prevHoveredItems) => {
            return { ...prevHoveredItems, [index]: false };
        });
    };

    const renderListOfBG = (list) => {
        return list.map((background, index) => (
            <button key={"backgroundButton_" + index}
                    className="backgroundButton" onClick={() => {
                        setTempBackgroundIndex(index)
                    }}
                    onMouseEnter={() => handleMouseEnter(index)}
                    onMouseLeave={() => handleMouseLeave(index)}
                    style={{backgroundColor: hoveredItems[index] ? theme.colors[themeIndex].header : "initial"}}
            >{background.name}</button>
        ))
    }


    const handleFocus = (index) => {
        setFocusedIndex(index);
    };

    const renderThemeColors = (list) => {
        return list.map((color, index) => (
            <button key={"themeColor_choice_" + index}
                    className="themeColor_choice"
                    onClick={() => handleTheme(index)}
                    onFocus={() => handleFocus(index)}
                    style={{border: focusedIndex === index ? "2px solid white" : "2px solid rgba(35, 42, 50, 1)"}}
            >
                <div className="color color1" style={{backgroundColor: color.windowNotTransparent}}></div>
                <div className="color color2" style={{backgroundColor: color.header}}></div>
            </button>
        ))
    }

    const renderThemeColorsRecommend = (list) => {
        return list.map((number, index) => (
            <button key={"themeColor_choice_" + index}
                    className="themeColor_choice"
                    onClick={() => handleTheme(number)}
                    onFocus={() => handleFocus(number)}
                    style={{border: focusedIndex === number ? "2px solid white" : "2px solid rgba(35, 42, 50, 1)"}}
            >
                <div className="color color1" style={{backgroundColor: theme.colors[number].windowNotTransparent}}></div>
                <div className="color color2" style={{backgroundColor: theme.colors[number].header}}></div>
            </button>
        ))
    }

    const handleTheme = (index) => {
        setTempThemeIndex(index)
    }

    const handleValidate = () => {
        changeBackground(tempBackgroundIndex);
        changeTheme(tempThemeIndex);
        isNotVisible();
    }

    return (
        <div id="themeOptionWindow">
            <div id="backgroundSelector">
                <div id="backgroundPreview">
                    <img src={theme.backgrounds[tempBackgroundIndex].url} alt="Background Preview"/>
                    <img src={theme.backgrounds[tempBackgroundIndex].logo_url} alt="" id="Preview_logo"/>
                </div>
                <div id="backgroundList">
                    {renderListOfBG(theme.backgrounds)}
                </div>
            </div>
            <div id="colorSelector_Container">
                <div id="colorSelector">
                    <div className="themeColorSelector">
                        {renderThemeColors(theme.colors)}
                    </div>
                    <div className="themeColorSelector recommend">
                        <p>{trad_menus.themeOptionWindow[3]}</p>
                        <div id="colors">
                            {renderThemeColorsRecommend(theme.backgrounds[tempBackgroundIndex].recommend)}
                        </div>
                    </div>
                </div>
                <div id="submitButtons">
                    <Link className="button" to="/Desktop"><i className="fa-solid fa-check"></i>Ok</Link>
                    <button className="button validateButton"
                            onClick={handleValidate}
                            style={{
                                border: visible ? "1px solid darkgray" : "1px solid #3f3e3e",
                                color: visible ? "white" : "#3f3e3e"
                            }}
                            disabled={!visible}
                    >
                        <i className="fa-solid fa-check"></i>{trad_menus.themeOptionWindow[0]}
                    </button>
                    <Link className="button" to="/Desktop"><i className="fa-solid fa-ban"></i>{trad_menus.themeOptionWindow[1]}</Link>
                </div>
            </div>

            <Link to="https://www.myo-webdesign.fr/"
                  id="yannickIcon"
                  onMouseEnter={isHovered}
                  onMouseLeave={isNotHovered}
                  style={{backgroundColor: hover ? theme.colors[themeIndex].button_hover : "transparent"}}>
                <img src={images_links[2].yannickIcon} alt="Logo Yannick Myo"/>
            </Link>
        </div>
    )
}

