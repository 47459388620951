import "./Desktop.sass"

import {useContext} from "react";
import {ThemeContext} from "../../Lib/contexts/themeContext";
import {LangContext} from "../../Lib/contexts/LangContext";

import Dock from "./Dock/Dock"
import OptionsSelector from "../Options/OptionsSelector/OptionsSelector";
import ThemeOptionMobile from "../Options/ThemeOptionMobile/ThemeOptionMobile";
import {SizeContext} from "../../Lib/contexts/SizeContext";




export default function Desktop() {
    const {theme, backgroundIndex, themeIndex } = useContext(ThemeContext);
    const {trad_menus} = useContext(LangContext);
    const {windowSize} = useContext(SizeContext);


    return (
        (windowSize.width > 1100) ? (
            <main style={{
                backgroundImage: `url(${theme.backgrounds[backgroundIndex].url})`,
                height: windowSize.height,
            }}>
                <Dock id="dock"/>
                <OptionsSelector />
                <img src={theme.backgrounds[backgroundIndex].logo_url} id="bg_logo" alt=""/>
                <p id="dClick_message" style={{
                    backgroundColor: theme.colors[themeIndex].dock,
                    border: "1px solid " + theme.colors[themeIndex].window
                }}>{trad_menus.optionsMessage}
                </p>
            </main>

        ) : (
            <main style={{
                backgroundImage: `url(${theme.backgrounds[backgroundIndex].url})`,
                height: windowSize.height,
            }}>
                <div id="dock_conntainer">
                    <Dock id="dock"/>
                    <ThemeOptionMobile />
                </div>
                <img src={theme.backgrounds[backgroundIndex].logo_url} id="bg_logo" alt="" />
            </main>
        )

    )
}





