import "./DesktopIcon.sass";
import useVisible from "../../../Lib/hooks/useVisible";
import {useContext, useEffect} from "react";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {Link} from "react-router-dom";


export default function DesktopIcon({infos, target}) {
    const {visible, isVisible, isNotVisible}= useVisible();
    const {theme, themeIndex} = useContext(ThemeContext);

    useEffect(() => {
        isNotVisible();
    }, []);

    return(
        <Link to={infos.url} className="desktopIcon" onClick={isVisible} target={target ? "" : null} >
            <img src={infos.img_url} alt={infos.name} />
            <p style={{backgroundColor: visible ? theme.colors[themeIndex].button_hover : "transparent"}}>{infos.name}</p>
        </Link>
    )
}