import {Link} from "react-router-dom";

import images_links from "../../../Assets/Datas/images_links.json";

import {renderProjectsLinks} from "../../../Lib/utils";
import "./Dolphin.sass";
import {useContext, useState} from "react";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {LangContext} from "../../../Lib/contexts/LangContext";


export default function Dolphin() {
    const {theme, themeIndex} = useContext(ThemeContext);
    const {navigation, projects} =useContext(LangContext);
    const [hoveredItems, setHoveredItems] = useState({});

    const renderListOfNavLinks_hover = (list) => {
        const handleMouseEnter = (index) => {
            setHoveredItems((prevHoveredItems) => {
                return { ...prevHoveredItems, [index]: true };
            });
        };
        const handleMouseLeave = (index) => {
            setHoveredItems((prevHoveredItems) => {
                return { ...prevHoveredItems, [index]: false };
            });
        };

        return list.map((link, index) => (
            <Link key={link.name}
                  className="navLink"
                  to={link.url}
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={() => handleMouseLeave(index)}
                  style={{backgroundColor: hoveredItems[index] ? theme.colors[themeIndex].button_hover : "initial"}}
            >
                <img src={link.icon} alt={link.name} />
                <p>{link.name}</p>
            </Link>
        ))
    }


    return (
        <div id="glassDolphin">
            <nav style={{
                backgroundColor : theme.colors[themeIndex].header,
                borderTop: "1px solid " + theme.colors[themeIndex].window
            }}>
                <div id="icons">
                    <img src={images_links[1].leftArrow} alt="Flèche Gauche" />
                    <img src={images_links[1].rightArrow} alt="Flèche Droite" />
                </div>
                <div id="links">
                    <p className="titleMenu">Emplacements</p>
                    { renderListOfNavLinks_hover(navigation) }
                </div>
            </nav>
            <div id="dolphinContent">
                <div id="adresse">
                    <div className="adresseRepository">
                        <i className="fa-solid fa-house-chimney fa-2xs"></i>
                        <p>ArthurOS</p>
                    </div>
                    <div className="adresseRepository">
                        <i className="fa-solid fa-caret-right fa-2xs"></i>
                        <p>Portfolio</p>
                    </div>
                    <div className="adresseRepository">
                        <i className="fa-solid fa-caret-right fa-2xs"></i>
                        <p>Projects</p>
                    </div>
                </div>
                <div className="separationLine"></div>
                <div id="folderContent">
                    <Link to="/Projects/Readme" className="project">
                        <img src={navigation[5].icon[0]} alt="Icone d'un fichier texte" id="readmeLogo"/>
                        <p>Readme_First.txt</p>
                    </Link>
                    { renderProjectsLinks(projects) }
                </div>
            </div>
        </div>
    )
}