import {createContext, useState} from "react";


export const ThemeContext = createContext({})


export const ThemeProvider = ({children}) => {
    const [themeIndex, setThemeIndex] = useState(0);
    const [backgroundIndex, setBackgroundIndex] = useState(0);

    const changeTheme = (value) => {
        setThemeIndex(value);
    }

    const changeBackground = (value) => {
        setBackgroundIndex(value);
    }

    return (
        <ThemeContext.Provider value={{
            themeIndex,
            changeTheme,
            backgroundIndex,
            changeBackground,
            theme : {
                backgrounds: [
                    {
                        name: "Ubuntu ArthurOS Edition",
                        url: "https://arthur-dev.com/Images/Backgrounds/2.jpeg",
                        logo_url: "https://arthur-dev.com/Images/Backgrounds/Logos/logo2.png",
                        recommend: [0,3]
                    },
                    {
                        name: "Lion Constellation",
                        url: "https://arthur-dev.com/Images/Backgrounds/3.jpg",
                        logo_url: "https://arthur-dev.com/Images/Backgrounds/Logos/logo3.png",
                        recommend: [1]
                    },
                    {
                        name: "FireBurn Lion",
                        url: "https://arthur-dev.com/Images/Backgrounds/4.jpg",
                        logo_url: "https://arthur-dev.com/Images/Backgrounds/Logos/logo4.png",
                        recommend: [3]
                    },
                    {
                        name: "Lion Typography",
                        url: "https://arthur-dev.com/Images/Backgrounds/5.jpg",
                        logo_url: "https://arthur-dev.com/Images/Backgrounds/Logos/logo5.png",
                        recommend: [4,0]
                    },
                    {
                        name: "Emerald of the Lion",
                        url: "https://arthur-dev.com/Images/Backgrounds/6.jpg",
                        logo_url: "https://arthur-dev.com/Images/Backgrounds/Logos/logo6.png",
                        recommend: [2]
                    }

                ],
                colors: [
                    {
                        header: "rgba(41, 0, 0, 1)",
                        window_header: "rgba(41, 0, 0, 1)",
                        window: "rgba(92, 0, 0, 0.75)",
                        windowNotTransparent: "rgba(92, 0, 0, 1)",
                        dock: "#1d0f10",
                        button: "rgba(41, 0, 0, 1)",
                        button_hover: "#c53137",
                        fontColor: "#c3cee1"
                    },
                    {
                        header: "#c02cf1",
                        window_header: "rgba(58, 0, 125, 1)",
                        window: "rgba(101, 20, 224, 0.75)",
                        windowNotTransparent: "rgba(101, 20, 224, 1)",
                        dock: "rgba(58, 0, 125, 1)",
                        button: "rgba(101, 20, 224, 0.75)",
                        button_hover: "#c02cf1",
                        fontColor: "#c3cee1"
                    },
                    {
                        header: "rgba(0, 128, 0, 1)",
                        window_header: "rgba(46, 46,46,1)",
                        window: "rgba(176, 206, 0, 0.75)",
                        windowNotTransparent: "rgba(176, 206, 0, 1)",
                        dock: "rgba(46, 46,46,1)",
                        button: "rgba(0, 128, 0, 1)",
                        button_hover: "rgba(0, 128, 0, 1)",
                        fontColor: "#c3cee1"
                    },
                    {
                        header: "rgba(161, 24, 12, 1)",
                        window_header: "rgba(46, 46, 46,1)",
                        window: "rgba(230, 123, 0, 0.75)",
                        windowNotTransparent: "rgba(230, 123, 0, 1)",
                        dock: "rgba(46, 46, 46,1)",
                        button: "rgba(230, 123, 0, 1)",
                        button_hover: "rgba(46, 46, 46,1)",
                        fontColor: "rgb(79,28,6)"
                    },
                    {
                        header: "rgba(200,35,57, 1)",
                        window_header: "rgba(46, 46, 46,1)",
                        window: "rgba(255, 135, 105, 0.75)",
                        windowNotTransparent: "rgba(255, 135, 105, 1)",
                        dock: "rgba(200,35,57, 1)",
                        button: "rgba(200,35,57, 1)",
                        button_hover: "rgba(46, 46, 46,1)",
                        fontColor: "#000000"
                    }
                ],
                icons: [
                    {
                        header: [
                            "https://arthur-dev.com/Icons/Header/Lions/lion_icon.webp",
                            "https://arthur-dev.com/Icons/Header/ArthurOS/ArthurOS.svg"
                        ],
                        dock: 0,
                        windowStateIcons : [
                            {
                                color:"#c53137",
                                image: "https://arthur-dev.com/Icons/Window state Icons/red/closeButton.svg",
                                link: true
                            },
                            {
                                color: "#fefefe",
                                image: "https://arthur-dev.com/Icons/Window state Icons/red/min_maxButton.svg",
                                link: false
                            },
                            {
                                color: "#fbbe48",
                                image: "https://arthur-dev.com/Icons/Window state Icons/red/reduceButton.svg",
                                link: true
                            }
                        ]
                    },
                    {
                        header: [
                            "https://arthur-dev.com/Icons/Header/Lions/lion_icon_purple.svg",
                            "https://arthur-dev.com/Icons/Header/ArthurOS/ArthurOS_purple.svg"
                        ],
                        dock: 0,
                        windowStateIcons : [
                            {
                                color:"#c02cf1",
                                image: "https://arthur-dev.com/Icons/Window state Icons/purple/closeButton.svg",
                                link: true
                            },
                            {
                                color: "white",
                                image: "https://arthur-dev.com/Icons/Window state Icons/purple/min_maxButton.svg",
                                link: false
                            },
                            {
                                color: "rgba( 101, 20, 224, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/purple/reduceButton.svg",
                                link: true
                            }
                        ]
                    },
                    {
                        header: [
                            "https://arthur-dev.com/Icons/Header/Lions/lion_icon_green.svg",
                            "https://arthur-dev.com/Icons/Header/ArthurOS/ArthurOS_green.svg"
                        ],
                        dock: 1,
                        windowStateIcons : [
                            {
                                color: "rgba(176, 206, 0, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/green/closeButton.svg",
                                link: true
                            },
                            {
                                color: "white",
                                image: "https://arthur-dev.com/Icons/Window state Icons/green/min_maxButton.svg",
                                link: false
                            },
                            {
                                color: "rgba(0, 128, 0, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/green/reduceButton.svg",
                                link: true
                            }
                        ]
                    },
                    {
                        header: [
                            "https://arthur-dev.com/Icons/Header/Lions/lion_icon_orange.svg",
                            "https://arthur-dev.com/Icons/Header/ArthurOS/ArthurOS_orange.svg"
                        ],
                        dock: 1,
                        windowStateIcons : [
                            {
                                color: "rgba(230, 123, 0, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/orange/closeButton.svg",
                                link: true
                            },
                            {
                                color: "white",
                                image: "https://arthur-dev.com/Icons/Window state Icons/orange/min_maxButton.svg",
                                link: false
                            },
                            {
                                color: "rgba(161, 24, 12, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/orange/reduceButton.svg",
                                link: true
                            }
                        ]
                    },
                    {
                        header: [
                            "https://arthur-dev.com/Icons/Header/Lions/lion_icon_pink.svg",
                            "https://arthur-dev.com/Icons/Header/ArthurOS/ArthurOS_pink.svg"
                        ],
                        dock: 1,
                        windowStateIcons : [
                            {
                                color: "rgba(200,35,57, 1)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/pink/closeButton.svg",
                                link: true
                            },
                            {
                                color: "white",
                                image: "https://arthur-dev.com/Icons/Window state Icons/pink/min_maxButton.svg",
                                link: false
                            },
                            {
                                color: "rgba(255,120, 98)",
                                image: "https://arthur-dev.com/Icons/Window state Icons/pink/reduceButton.svg",
                                link: true
                            }
                        ]
                    }
                ]
            }

        }}>
            {children}
        </ThemeContext.Provider>
    )
}