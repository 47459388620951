import "./Contact.sass"
import React, {useContext, useRef} from 'react';

import copyToClipboard_icon from "../../../../Assets/Icons/copyToClipboard_icon.png";
import sendMail_icon from "../../../../Assets/Icons/sendMail_icon.png";
import {ThemeContext} from "../../../../Lib/contexts/themeContext";
import ContactForm from "./ContactForm"
import Scrollbars from "react-custom-scrollbars-2";

export default function Contact() {
    const textToCopyRef = useRef(null);
    const {theme, themeIndex} = useContext(ThemeContext);


    const copyToClipboard = () => {
        if (textToCopyRef.current) {
            navigator.clipboard.writeText(textToCopyRef.current.value)
                .then(() => {
                    alert('Texte copié avec succès');
                })
                .catch(err => {
                    alert('Erreur lors de la copie dans le presse-papiers : ' + err);
                });
        }
    };

    return(
        <Scrollbars id="contact" autoHide autoHideTimeout={800} autoHideDuration={200}>
            <h1>Contact</h1>
            <div className="infos">
                <i className="fa-brands fa-linkedin" style={{color: "#0a66c2"}}></i>
                <a href="https://www.linkedin.com/in/arthur-dev-arnette" target="_blank" rel="noreferrer"
                   style={{color: theme.colors[themeIndex].fontColor}}>Profil Linkedin</a>
            </div>
            <div className="infos">
                <i className="fa-brands fa-github"></i>
                <a href="https://github.com/Arthuros91" target="_blank" rel="noreferrer"
                   style={{color: theme.colors[themeIndex].fontColor}}>Profil Github</a>
            </div>

            <ContactForm/>

        </Scrollbars>
    )
}