import "./Readme.sass"

import Scrollbars from "react-custom-scrollbars-2"
import { renderListofLi } from "../../../../Lib/utils"
import {useContext} from "react";
import {LangContext} from "../../../../Lib/contexts/LangContext";
import {ThemeContext} from "../../../../Lib/contexts/themeContext";

const ArthurOS = "https://arthur-dev.com/Images/Arthuros_ascii.png"


export default function Changelog() {

    const {trad_content} =useContext(LangContext);
    const {theme, themeIndex} = useContext(ThemeContext);
    return(
        <Scrollbars id="readme" autoHide autoHideTimeout={800} autoHideDuration={200}>
            <div id="ArthurOS_ASCII">
                <img src={ArthurOS} alt="ASCII Art de ArthurOS" />
            </div>
            <h1>Change log</h1>
            <ul id="recentUpdates">
                {trad_content.changelog.updates.map((update) => (
                    <li>
                        <h2>{trad_content.changelog.h2_1 + update.version}</h2>
                        <ul className="changesList">
                            {update.changes.map((change) => (
                                <li style={{color: theme.colors[themeIndex].fontColor}}>{change}</li>
                            ))}
                        </ul>
                    </li>
                ))}
            </ul>

            <h2>{trad_content.changelog.h2_2}</h2>
            <ul>
                {renderListofLi(trad_content.changelog.futuresUpdates, theme.colors[themeIndex].fontColor)}
            </ul>
            <div id="thanks">
                <h2>Crédits / Special thanks</h2>
                <p style={{color: theme.colors[themeIndex].fontColor}}>Yannick Maillot, web designer ( <a href="https://www.myo-webdesign.fr/" target="_blank" rel="noreferrer">site web</a> )</p>
                <p style={{color: theme.colors[themeIndex].fontColor}}>Kevin Roth, mentor OpenClassrooms</p>
                <p style={{color: theme.colors[themeIndex].fontColor}}>Patxi Iparaguirre, mentor OpenClassrooms ( <a href="https://fr.linkedin.com/in/patxi-iparaguirre" target="_blank" rel="noreferrer">Profil Linkedin</a> ) </p>
            </div>
        </Scrollbars>
    )
}