import React, {useContext} from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';


import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './Slider.sass';

// import required swiper modules
import { EffectCoverflow, Navigation, Pagination } from 'swiper/modules';
import {ThemeContext} from "../../../../Lib/contexts/themeContext";
import {SliderContext} from "../../../../Lib/contexts/SliderContext";
import {LangContext} from "../../../../Lib/contexts/LangContext";


export default function Slider() {
    const {theme, themeIndex } = useContext(ThemeContext)
    const {changeSliderIndex} = useContext(SliderContext)
    const {projects} = useContext(LangContext)

    const onChangeSlide = (swiper) => {
        changeSliderIndex(swiper.activeIndex)
    }
    return (
        <Swiper onSlideChange={onChangeSlide}
            effect={'coverflow'}
            grabCursor={true}
            centeredSlides={true}
            navigation={true}
            slidesPerView={'auto'}
            coverflowEffect={{
                rotate: 50,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
            }}
            pagination={true}
            modules={[EffectCoverflow, Pagination, Navigation]}
            className="mySwiper"
        >
            {projects.map((vignette, index) => (
                <SwiperSlide key={index} className="swiper-slide">

                    <img src={vignette.icon} alt={vignette.name} />
                </SwiperSlide>
            ))}
        </Swiper>
    );
};