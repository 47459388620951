import "./ButtonLink.sass";
import {Link} from "react-router-dom";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {useContext} from "react";
import useHover from "../../../Lib/hooks/useHover";


export function ButtonLink({text, url}) {
    const { theme, themeIndex } = useContext(ThemeContext);
    const {hover,isHovered,isNotHovered} = useHover();
    return (
        <div className="buttonLink_container">
            <Link className="buttonLink"
                  to={url}
                  onMouseEnter={isHovered}
                  onMouseLeave={isNotHovered}
                  style={{backgroundColor: hover ?
                              theme.colors[themeIndex].button_hover
                              :
                              "white",color: hover ? "white" : theme.colors[themeIndex].windowNotTransparent}}>
                {text}
            </Link>
        </div>

    )
}