import "./ContactForm.sass";
import emailjs from "@emailjs/browser"

import { useState } from "react";
import Scrollbars from "react-custom-scrollbars-2";
import {redirect} from "react-router-dom";




export default function ContactForm() {

    emailjs.init({
        publicKey: "oqRKF4nTCYLKbMEXw",
        // Do not allow headless browsers
        blockHeadless: true,
        limitRate: {
          // Set the limit rate for the application
          id: 'app',
          // Allow 1 request per 10s
          throttle: 10000,
        },
    });
    
    const SERVICE_ID = "service_8rwenvq";
    const TEMPLATE_ID = "template_j89n7mj";
    const USER_ID = "oqRKF4nTCYLKbMEXw";


    const [firstName, setFirstName] = useState("");
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [sent,setSent] = useState(false)


    const sendEmail = () => {
        const templateParams = {
            firstName,
            email,
            message,
        };
    
        emailjs
          .send(SERVICE_ID, TEMPLATE_ID, templateParams)
          .then(
                (response) => {
                console.log('Email envoyé avec succès!', response.status, response.text);
                },
                (error) => {
                console.error('Erreur lors de l\'envoi de l\'email:', error);
                }
          );
        };
        const handleSubmit = (e) => {
            e.preventDefault();
            sendEmail();
            setSent(true);
        };


        return (
            <form onSubmit={handleSubmit}>
                    <div>
                        <label>
                            <p>Nom:</p>
                            <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)}
                                   required/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <p>Email:</p>
                            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required/>
                        </label>
                    </div>
                    <div>
                        <label>
                            <p>Message:</p>
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} required cols={40}
                                      rows={8}/>
                        </label>
                    </div>
                    <button id="contactSubmit" type="submit">{sent ? (<i class="fa-solid fa-check"></i>) : ("ENVOYER")}</button>
            </form>
        );


}


