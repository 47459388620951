import {useState} from "react";

export default function useVisible() {
    const [isVisible, setIsVisible] = useState(false);

    return {
        visible: isVisible,
        isVisible: () => {setIsVisible(true)},
        isNotVisible: () => {setIsVisible(false)}
    }
}