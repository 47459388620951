import React, {useContext} from "react";
import "./ProjectsViewer.sass";

import {SliderContext} from "../../../Lib/contexts/SliderContext";
import {LangContext} from "../../../Lib/contexts/LangContext";
import {ThemeContext} from "../../../Lib/contexts/themeContext";

import Slider from "./Slider/Slider";





export default function ProjectsViewer() {
    const {sliderIndex} = useContext(SliderContext);
    const {projects, trad_content} =useContext(LangContext);
    const {theme, themeIndex} = useContext(ThemeContext);


    return (
        <div id="projectsViewer">
            <Slider />
            <div id="infos">
                <h2 style={{color: projects[sliderIndex].color}}>{projects[sliderIndex].name}</h2>
                <h3>Mission :</h3>
                <p style={{color: theme.colors[themeIndex].fontColor}}>{projects[sliderIndex].description}</p>
                <h3>{trad_content.readmeFirst.h3_1}</h3>
                <p style={{color: theme.colors[themeIndex].fontColor}}>{projects[sliderIndex].languages}</p>
                <h3>{trad_content.readmeFirst.a}</h3>
                <a href={projects[sliderIndex].projectUrl} target="_blank" rel="noreferrer" style={{color: theme.colors[themeIndex].fontColor}}>{trad_content.readmeFirst.a2}</a><br/>
                <a href={projects[sliderIndex].github} target="_blank" rel="noreferrer" style={{color: theme.colors[themeIndex].fontColor}}>Repo Github</a>
            </div>
        </div>
    )
}