import {createContext, useEffect, useState} from "react";

import en_menus from "../../Assets/Lang/Menus/en.json";
import fr_menus from "../../Assets/Lang/Menus/fr.json";
import en_content from "../../Assets/Lang/Content/en.json";
import fr_content from "../../Assets/Lang/Content/fr.json";

export const LangContext = createContext({});

export const LangProvider = ({children}) => {
    const [lang, setLang] = useState(navigator.language.split('-')[0]);
    const [trad_menus, setTrad_menus] = useState(en_menus);
    const [trad_content, setTrad_content] = useState(en_content);

    const [categories, setCategories] = useState(en_menus.categories);
    const [navigation, setNavigation] = useState(en_menus.navigation);
    const [projects, setProjects] = useState(en_content.projects);
    const toggleLang = () => {
        setLang((prevLang) => (prevLang === 'fr' ? 'en' : 'fr'));
    };

    useEffect(() => {
        if (lang === "fr") {
            setTrad_menus(fr_menus);
            setTrad_content(fr_content);
            setCategories(fr_menus.categories);
            setNavigation(fr_menus.navigation)
            setProjects(fr_content.projects)
        } else {
            setTrad_menus(en_menus);
            setTrad_content(en_content);
            setCategories(en_menus.categories);
            setNavigation(en_menus.navigation);
            setProjects(en_content.projects)
        }
    }, [lang]);

    return (
        <LangContext.Provider value={{
            lang,
            toggleLang,
            trad_menus,
            trad_content,
            categories,
            navigation,
            projects
        }}>
            {children}
        </LangContext.Provider>
    )
}