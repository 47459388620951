import "./OptionsSelector.sass"
import React, {useContext, useState} from 'react';
import {Link} from "react-router-dom";

import {SizeContext} from "../../../Lib/contexts/SizeContext";
import {LangContext} from "../../../Lib/contexts/LangContext";
import {ThemeContext} from "../../../Lib/contexts/themeContext";

import useVisible from "../../../Lib/hooks/useVisible";
import useHover from "../../../Lib/hooks/useHover";
import {renderDesktopIcons} from "../../../Lib/utils";

import image_icon from "../../../Assets/Icons/image-icon.png"




export default function OptionsSelector() {
    const {theme, themeIndex} = useContext(ThemeContext);
    const {trad_menus} = useContext(LangContext)
    const {windowSize, fullscreenSize, optionsWindowSize} = useContext(SizeContext);

    const [mousePosition, setMousePosition] = useState({ x: null, y: null });
    const [mousePositionOnClick, setMousePositionOnClick] = useState({ x: null, y: null });

    const {visible, isVisible, isNotVisible} = useVisible();
    const {hover,isHovered, isNotHovered} = useHover();


    const handleMouseMove = (e) => {
        setMousePosition({ x: e.clientX, y: e.clientY });
    };
    const handleDoubleClick = () => {
        if(windowSize.width > 1100) {
            window.getSelection().removeAllRanges();
            setOptionsWindowPosition();
            isVisible();
        }
    }

    const setOptionsWindowPosition = () => {
        if (mousePosition.x + optionsWindowSize.width > windowSize.width) {
            setMousePositionOnClick((prevMousePosition) => {
                return { ...prevMousePosition, x: windowSize.width - optionsWindowSize.width - 103};
            });
        } else {
            setMousePositionOnClick((prevMousePosition) => {
                return { ...prevMousePosition, x: mousePosition.x - 103};
            });
        }

        if (mousePosition.y + optionsWindowSize.height > windowSize.height) {
            setMousePositionOnClick((prevMousePosition) => {
                return { ...prevMousePosition, y: windowSize.height - optionsWindowSize.height - 45};
            });
        } else {
            setMousePositionOnClick((prevMousePosition) => {
                return { ...prevMousePosition, y: mousePosition.y - 45};
            });
        }
    }


    /* Close modale by click */
    const handleClickOutside = (event) => {
        if (event.target === event.currentTarget) {
            isNotVisible();
        }
    };


    return (
        <div id="optionsSelector"
             onMouseMove={handleMouseMove}
             onDoubleClick={handleDoubleClick}
             onClick={handleClickOutside}
             style={{
                width: fullscreenSize.width,
                height: fullscreenSize.height
        }}>
            <div id="desktopIcons">
                {renderDesktopIcons(trad_menus.desktopIcons)}
            </div>
            <div id="optionsSelector_window" style={{
                display: visible ? "flex" : "none",
                top: `${mousePositionOnClick.y}px`,
                left: `${mousePositionOnClick.x}px `,
                backgroundColor: theme.colors[themeIndex].window}}
            >
                <Link className="optionsSelector_link"
                      to="options/changeTheme"
                      key="optionsSelector_link"
                      onClick={isNotVisible}
                      onMouseEnter={isHovered}
                      onMouseLeave={isNotHovered}
                      style={{backgroundColor: hover ? theme.colors[themeIndex].button_hover : "transparent"}}
                >
                    <img src={image_icon} alt="logo changer thème" className="optionsSelector_icon"/>
                    Changer le fond d'écran
                </Link>
            </div>
        </div>
    )
}



