import {useContext, useEffect, useRef} from "react";
import {LangContext} from "../../../Lib/contexts/LangContext";
import {gsap} from "gsap";


export default function Error404() {
    const {trad_menus} =useContext(LangContext);
    const dotRefs = useRef([]);

    useEffect(() => {
        const tl = gsap.timeline({ repeat: -1 });
        tl.set(dotRefs.current, { opacity: 0 });
        for (let i = 0; i < dotRefs.current.length; i++) {
            tl.to(dotRefs.current[i], { opacity: 1, duration: 0.3 })
                .to(dotRefs.current[i], { opacity: 0, duration: 0.3 });
        }
    }, []);

    return(
        <div id="KTerminal">
            <div id="error404">
                {trad_menus.error404}
                <div className="dots-container">
                    <div ref={el => dotRefs.current[0] = el} className="dot">.</div>
                    <div ref={el => dotRefs.current[1] = el} className="dot">.</div>
                    <div ref={el => dotRefs.current[2] = el} className="dot">.</div>
                </div>
            </div>

        </div>
    )
}