import "./Header.sass"

import {useContext, useEffect, useState} from "react"
import { Link } from "react-router-dom"

import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {LangContext} from "../../../Lib/contexts/LangContext";
const dayjs = require('dayjs')


export default function Header() {
    const [time, setTime] = useState(dayjs().format("HH:mm"));
    const { theme, themeIndex } = useContext(ThemeContext);
    const {lang, toggleLang} = useContext(LangContext);

    useEffect(() => {
        const interval = setInterval(() => {
        setTime(dayjs().format("HH:mm"));
        }, 10000);

        return () => clearInterval(interval);
    }, []);


    return(
        <header style={{backgroundColor: theme.colors[themeIndex].header}}>
            <button><img src={theme.icons[themeIndex].header[0]} alt="logo_OS"/></button>
            <Link to="/Desktop"><img id="logo_ArthurOS"
                                     src={theme.icons[themeIndex].header[1]}
                                     alt="Logo ArthurOS"/>
            </Link>
            <div id="timeAndLang">
                <button onClick={toggleLang} id="langSelector"><p>{lang.toUpperCase()}</p></button>
                <div id="time">{time}</div>
            </div>
        </header>
    )
}

