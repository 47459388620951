import {useState, useRef, useEffect, useContext} from "react";
import { Link } from "react-router-dom"
import "./GlassWindow.sass";
import { gsap } from "gsap";
import { Draggable } from "gsap/Draggable";
import WindowStateIcon from "../WindowStateIcon/WindowStateIcon";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {SizeContext} from "../../../Lib/contexts/SizeContext";


gsap.registerPlugin(Draggable);

export default function GlassWindow({category, component}) {
    const windowWidth= window.innerWidth;
    const [isFullScreen, setIsFullScreen] = useState(false);
    const {theme, themeIndex} = useContext(ThemeContext);
    const dragInstance = useRef(null);
    const dragTarget = useRef(null);

    const {windowSize, fullscreenSize} = useContext(SizeContext);

    useEffect(() => {
        dragInstance.current = Draggable.create(dragTarget.current, {
            type: "x,y",
            trigger: [".window_header"],
            bounds: ["main", "#dock"],
        });
    }, []);

    const toggleFullScreen = () => {
        setIsFullScreen(prevState => !prevState);
    };

    const renderWindowStateIcon = (list) => {
        return list.map((element, index) => (
            (element.link === true) ? (
                <Link key={index} to="/Desktop" className="link">
                    <WindowStateIcon color={element.color} img={element.image} />
                </Link>
            ) : (
                <WindowStateIcon key={index} color={element.color} img={element.image} clickFunction={toggleFullScreen}/>
            )
        ));
    };
    return (
        (windowSize.width > 1100) ? (
            <div id="windowGlass"
                 ref={dragTarget}
                 className={`${isFullScreen ? "full-screen" : ""}`}
                 style={{
                     backgroundColor: theme.colors[themeIndex].window,
                     width: isFullScreen ? fullscreenSize.width + "px" : "70vw",
                     height: isFullScreen ? fullscreenSize.height + "px" : "75vh",
                     top: isFullScreen ? "40px" : "12%",
                     left: isFullScreen ? "95px" : "15%",
                 }}>
                <div className="window_header" style={{backgroundColor: theme.colors[themeIndex].window_header}}>
                    <div className="navButtons">
                        {renderWindowStateIcon(theme.icons[themeIndex].windowStateIcons)}
                    </div>
                    <div className="window_infos"><p className="categoryTitle">{category}</p></div>
                </div>
                <div id="content">
                    {component}
                </div>
            </div>
        ) : (
            <div id="windowGlass"
                 style={{
                     backgroundColor: theme.colors[themeIndex].window,
                 }}>
                <div className="window_header" style={{backgroundColor: theme.colors[themeIndex].window_header}}>
                    <div className="navButtons">
                        {renderWindowStateIcon(theme.icons[themeIndex].windowStateIcons)}
                    </div>
                    <div className="window_infos"><p className="categoryTitle">{category}</p></div>
                </div>
                <div id="content">
                    {component}
                </div>
            </div>
        )
    )
}