import "./Okular.sass"

import { useState } from "react"
import images_links from "../../../Assets/Datas/images_links.json"



export default function Okular({content}) {
    const [docSize, setDocSize] = useState(75)
    const handleZoomIn = () => {
        if(docSize === 100){

        } else {
            setDocSize(docSize + 25)
        }
    }

    const handleZoomOut = () => {
        if(docSize === 25){
            return
        } else {
            setDocSize(docSize - 25)
        }
    }


    return(
        <div id="Okular">
            <div id="menuBar">
                <button className="menubutton" onClick={handleZoomIn}><img src={images_links[1].zoomIn} alt="" /></button>
                <div id="number">{docSize +25}%</div>
                <button className="menubutton" onClick={handleZoomOut}><img src={images_links[1].zoomOut} alt="" /></button>
            </div>
            <div id="okularContent" style={{width: `${docSize}%`}} >
                {content}
            </div>
        </div>
    )
}