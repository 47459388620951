import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {useContext} from "react"

import Header from "./Components/Desktop/Header/Header";
import Desktop from "./Components/Desktop/Desktop";
import GlassWindow from "./Components/Window/GlassWindow/GlassWindow";

import ThemeOptionWindow from "./Components/Options/ThemeOptionWindow/ThemeOptionWindow";
import Okular from "./Components/WindowContent/Okular/Okular";
import AboutMe from "./Components/WindowContent/Okular-Content/AboutMe/AboutMe";
import Skills from "./Components/WindowContent/Okular-Content/Skills/Skills";
import Contact from "./Components/WindowContent/Okular-Content/Contact/Contact";
import Changelog from "./Components/WindowContent/Okular-Content/ReadMe/Changelog";
import ProjectsViewer from "./Components/WindowContent/ProjectsViewer/ProjectsViewer";
import Dolphin from "./Components/WindowContent/Dolphin/Dolphin";
import ReadmeProjects from "./Components/WindowContent/Okular-Content/ReadmeProject/ReadmeProject";
import {LangContext} from "./Lib/contexts/LangContext";
import Error404 from "./Components/Others/Error404/Error404";



export default function App() {
    const {categories} = useContext(LangContext);

    return (
        <Router>
            <Header />
            <Desktop />
            <Routes>
                <Route path="/" element={<GlassWindow component={ <Okular content={<AboutMe /> } /> } category={categories.aboutMe} />} />
                <Route path="/Desktop" element={<></>}/>
                <Route path="/options/changeTheme" element={<GlassWindow component={<ThemeOptionWindow />} category={categories.themeOptions}  />}/>
                <Route path="/About_me" element={<GlassWindow component={ <Okular content={<AboutMe /> } /> } category={categories.aboutMe} /> } />
                <Route path="/Skills" element={<GlassWindow component={<Okular content={<Skills />} /> } category={categories.skills} /> }/>
                <Route path="/Projects/Dolphin" element={<GlassWindow component={<Dolphin />} category={categories.projects} /> } />
                <Route path="/Projects/Slider" element={<GlassWindow component={<ProjectsViewer />} category={categories.projectsViewer} />}/>
                <Route path="/Projects/Readme" element={<GlassWindow component={<ReadmeProjects /> } category={categories.readmeProjects} /> } />
                <Route path="/Contact" element={<GlassWindow component={<Okular content={<Contact />} /> } category={categories.contact} /> } />
                <Route path="/Changelog" element={<GlassWindow category={categories.changelog} component={<Okular content={<Changelog />}/>} /> } />
                <Route path="*" element={<GlassWindow component={<Error404 />}  category="Error 404" />} />
            </Routes>
        </Router>
    );
}


