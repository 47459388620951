import "./Readme_Projects.sass"

import Scrollbars from "react-custom-scrollbars-2"
import {useContext} from "react";
import {LangContext} from "../../../../Lib/contexts/LangContext";
import {ThemeContext} from "../../../../Lib/contexts/themeContext";

const ArthurOS = "https://arthur-dev.com/Images/Arthuros_ascii.png"



export default function ReadmeProjects() {
    const {projects, trad_content} =useContext(LangContext);
    const {theme, themeIndex} = useContext(ThemeContext);
    const renderListofProjects = (list) => {
        return list.map( link => 
            <div className="project" key={link.name} >
                <h2 style={{color: link.color}}>{link.name}</h2> 
                <h3>Mission :</h3>
                <p style={{color: theme.colors[themeIndex].fontColor}}>{link.description}</p>
                <h3>{trad_content.readmeFirst.h3_1}</h3>
                <p style={{color: theme.colors[themeIndex].fontColor}}>{link.languages}</p>
                <a href={link.projectUrl} target="_blank" rel="noreferrer" style={{color: theme.colors[themeIndex].fontColor}}>{trad_content.readmeFirst.a}</a><br />
                <a href={link.github} target="_blank" rel="noreferrer" style={{color: theme.colors[themeIndex].fontColor}}>Repo Github</a>
            </div>
        )
    }


    return(
        <Scrollbars id="readme_Projects" autoHide autoHideTimeout={800} autoHideDuration={200}>
            <div id="ArthurOS_ASCII">
                <img src={ArthurOS} alt="ASCII Art de ArthurOS" />
            </div>
            <div id="ArthurOS_ASCII">
                <h1>{trad_content.readmeFirst.h1}</h1>
            </div>
            {renderListofProjects(projects)}
            
        </Scrollbars>
    )
}