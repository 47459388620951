import {createContext, useState} from "react";


export const SliderContext = createContext({})


export const SliderProvider = ({children}) => {
    const [sliderIndex, setSliderIndex] = useState(0);

    const changeSliderIndex = (value) => {
        setSliderIndex(value);
    }


    return(
        <SliderContext.Provider value={{
            sliderIndex,
            changeSliderIndex
        }}>
            {children}
        </SliderContext.Provider>
    )
}