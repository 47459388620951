import "./WindowStateIcon.sass"
import useHover from "../../../Lib/hooks/useHover";


export default function WindowStateIcon({img, color, clickFunction}) {
    const {hover,isHovered,isNotHovered} = useHover();

    return(
        <button
            className="window_state_icon"
            onMouseEnter={isHovered}
            onMouseLeave={isNotHovered}
            onClick={clickFunction}
        >
            {
                (hover) ? (
                    <img src={img} alt=""/>
                ) : (
                    <div className="round" style={{backgroundColor: color}}></div>
                )
            }
        </button>
    )
}