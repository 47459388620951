import {useState} from "react";

export default function useHover() {
    const [isHovered, setIsHovered] = useState(false);

    return {
        hover: isHovered,
        isHovered: () => {setIsHovered(true)},
        isNotHovered: () => {setIsHovered(false)}
    }
}