import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {ThemeProvider} from "./Lib/contexts/themeContext";
import {SliderProvider} from "./Lib/contexts/SliderContext";
import {LangProvider} from "./Lib/contexts/LangContext";
import {SizeProvider} from "./Lib/contexts/SizeContext";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <SizeProvider>
        <LangProvider>
            <ThemeProvider>
                <SliderProvider>
                    <App />
                </SliderProvider>
            </ThemeProvider>
        </LangProvider>
        </SizeProvider>
    </React.StrictMode>
);

