import "./AboutMe.sass"


import Scrollbars from "react-custom-scrollbars-2"
import { renderListofP } from "../../../../Lib/utils"
import {useContext} from "react";
import {ButtonLink} from "../../../Others/ButtonLink/ButtonLink";
import {LangContext} from "../../../../Lib/contexts/LangContext";
import {ThemeContext} from "../../../../Lib/contexts/themeContext";



export default function AboutMe() {
    const {trad_content} = useContext(LangContext);
    const {theme, themeIndex } = useContext(ThemeContext);

    return(
        <Scrollbars autoHide autoHideTimeout={800} autoHideDuration={200}>
            <h1>{trad_content.aboutMe.h1}</h1>
            <h2>{trad_content.aboutMe.h2}</h2>
            {renderListofP(trad_content.aboutMe.text, theme.colors[themeIndex].fontColor)}
            <ButtonLink text={trad_content.aboutMe.skillsLink} url="/Skills" />
        </Scrollbars>
    )
}