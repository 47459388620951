import "./Dock.sass"
import { Link } from "react-router-dom"

import {useContext, useEffect, useState} from "react";
import {ThemeContext} from "../../../Lib/contexts/themeContext";
import {LangContext} from "../../../Lib/contexts/LangContext";



export default function Dock() {
    const {theme, themeIndex } = useContext(ThemeContext)
    const [dockIndex, setDockIndex] = useState(0)
    const {navigation} = useContext(LangContext);

    useEffect(() => {
        setDockIndex(theme.icons[themeIndex].dock)
    },[themeIndex])

    return(
        <div id="dock" style={{
            backgroundColor: theme.colors[themeIndex].dock,
            border: "1px solid " + theme.colors[themeIndex].window
        }}>
            {
                navigation.map((nav) => (
                    <Link className="Link" to={nav.url} >
                        <img src={nav.icon[dockIndex]} alt={nav.name + "_icon"}/>
                    </Link>
                ))
            }
        </div>
    )  
}