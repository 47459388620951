import {Link} from "react-router-dom";

import DesktopIcon from "../Components/Others/DesktopIcon/DesktopIcon";



export function renderListofLi(list, colorOfLi) {
    return list.map(( element, index ) => 
        (<li key={index} style={{color: colorOfLi}}>{element}</li>)
    );
}

export function renderListofP(list,colorText) {
    return list.map(( element, index ) => 
        (<p key={index} style={{color: colorText}}>{element}</p>)
    );
}


export const renderListofLinks = (list,  color) => {

    return list.map( link =>
        <Link key={link.name} className="navLink" to={link.dolphinLink} >
            <img src={link.icon} alt={link.name} />
            <p>{link.name}</p>
        </Link>
    )
}

export const renderProjectsLinks = (list) => {
    return list.map( link =>
        <a key={link.name} className="project" href={link.projectUrl} target="_blank" rel="noreferrer">
            <img src={link.icon} alt={link.name} />
        </a>
    )
}


export const renderDesktopIcons = (list) => {
    return list.map((iconInfos) => (
        <DesktopIcon infos={iconInfos} target={iconInfos.target}/>
    ))
}





