import {createContext, useEffect, useState} from "react";

export const SizeContext = createContext({});

export const SizeProvider = ({children}) => {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight - 35
    });
    const fullscreenSize = {
        width: window.innerWidth - 100,
        height: window.innerHeight - 35
    };
    const optionsWindowSize = { width: 250, height: 215 };

    const changeSize = (widthValue, heightValue) => {
        setWindowSize({
            width: widthValue,
            height: heightValue
        })
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            changeSize(window.innerWidth, window.innerHeight - 35);
        });
    }, []);

    return (
        <SizeContext.Provider value={{
            windowSize,
            fullscreenSize,
            optionsWindowSize,
            changeSize
        }}>
            {children}
        </SizeContext.Provider>
    )
}